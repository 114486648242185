import { Typography } from "antd";
import ReelBg from "assets/images/onboarding/reel-bg.png";
import StoryBg from "assets/images/onboarding/story-bg.png";

interface Props {
  // Define your component's props here
}

const Expectations: React.FC<Props> = (props) => {
  // Implement your component logic here

  return (
    <div>
      <Typography.Title level={2}>What to expect</Typography.Title>
      <Typography.Title level={5}>
        One free collaboration a month
      </Typography.Title>{" "}
      <Typography.Paragraph>
        Each collaborations will deliver:
      </Typography.Paragraph>
      <div className="instagram-content">
        <div className="container">
          <span>Instagram Reel</span>
          <img src={ReelBg} alt="" />
        </div>
        <div className="container">
          <span>Instagram Story</span>
          <img src={StoryBg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Expectations;
