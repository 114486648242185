import { useState } from "react";
import BusinessesModal from "./BusinessesModal";

const ChangeBusinessModalControl: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <span onClick={() => setOpen(true)}>Switch profile</span>
      <BusinessesModal open={open} onCancel={() => setOpen(false)} />
    </>
  );
};

export default ChangeBusinessModalControl;
