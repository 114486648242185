import { Checkbox, Form, Typography } from "antd";
import { ReactComponent as LabelIcon } from "assets/images/onboarding/label-icon.svg";
import { ReactComponent as CakeIcon } from "assets/images/onboarding/cake-icon.svg";
import { ReactComponent as DrinkIcon } from "assets/images/onboarding/drink-icon.svg";
import { ReactComponent as RocketIcon } from "assets/images/onboarding/rocket-icon.svg";

interface Props {
  // Define your component's props here
}

const BusinessGoal: React.FC<Props> = () => {
  return (
    <div>
      <Typography.Title level={2}>Business Goal</Typography.Title>
      <Form.Item name="boostAwareness" valuePropName="checked">
        <Checkbox className="checkbox">
          Boost awareness <RocketIcon />
        </Checkbox>
      </Form.Item>
      <Form.Item name="promoteSpecificDishDrink" valuePropName="checked">
        <Checkbox className="checkbox">
          Promote specific dishes/drinks <DrinkIcon />
        </Checkbox>
      </Form.Item>
      <Form.Item name="raiseAwareness" valuePropName="checked">
        <Checkbox className="checkbox">
          Raise awareness about event <CakeIcon />
        </Checkbox>
      </Form.Item>
      <Form.Item name="generateMoreSales" valuePropName="checked">
        <Checkbox className="checkbox">
          Generate more sales <LabelIcon />
        </Checkbox>
      </Form.Item>
    </div>
  );
};

export default BusinessGoal;
