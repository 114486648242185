import { Button, Form, Input, Modal, Typography, message } from "antd";
import React, { useState } from "react";
import {
  rescheduleApplicationTargetTime,
  rescheduleBusinessApplication,
} from "services/SauceService";
import { ReactComponent as RescheduleIcon } from "assets/images/reschedule-icon.svg";
import { useNavigate } from "react-router-dom";

interface RescheduleRequestProps {
  open?: boolean;
  bookingId: string;
  collabId: string;
  callback?: () => void;
}

const FormDateSuggestions: React.FC<{
  form: any;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}> = (props) => {
  const { form, loading, onSubmit, onCancel } = props;

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item
        name="date"
        rules={[{ required: true, message: "Please select a date" }]}
      >
        <Input type="date" min={new Date().toISOString().split("T")[0]} />
      </Form.Item>
      <Form.Item
        name="time"
        rules={[{ required: true, message: "Please select a time" }]}
      >
        <Input type="time" />
      </Form.Item>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Suggest
        </Button>
        <Button
          style={{ justifySelf: "flex-end" }}
          onClick={onCancel}
          loading={loading}
        >
          Back
        </Button>
      </div>
    </Form>
  );
};

const FormMessage: React.FC<{
  form: any;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}> = (props) => {
  const { form, loading, onSubmit, onCancel } = props;

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item
        name="fieldMessage"
        rules={[
          {
            required: true,
            message: "Provide a @username or a URL to your account.",
          },
        ]}
      >
        <Input.TextArea placeholder="Message" />
      </Form.Item>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Button type="primary" htmlType="submit" loading={loading}>
          Send
        </Button>
        <Button
          style={{ justifySelf: "flex-end" }}
          onClick={onCancel}
          loading={loading}
        >
          Back
        </Button>
      </div>
    </Form>
  );
};

const RescheduleRequest: React.FC<RescheduleRequestProps> = (props) => {
  const { bookingId, collabId, open = false } = props;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [showReschedule, setShowReschedule] = useState<boolean>(open);
  const [dateSelect, setDateSelect] = useState<boolean>(false);
  const [messageSelected, setMessageSelected] = useState<boolean>(false);

  const title = dateSelect
    ? "Suggest another date"
    : messageSelected
    ? "Send a message"
    : "Reschedule";

  const onFinishFormDateSuggestions = async () => {
    const { date, time } = form.getFieldsValue();
    setLoading(true);
    try {
      await rescheduleApplicationTargetTime({
        bookingId: bookingId,
        collabId: collabId,
        newBookDate: date,
        newBookHour: time,
      });
      setDateSelect(false);
      message.success("Application rescheduled successfully");
      setShowReschedule(false);

      navigate(".", { replace: true });
    } catch (error) {
      message.error("Error rescheduling application");
      console.log(error);
    }

    setLoading(false);
  };

  const onFinishFormMessage = async () => {
    const { fieldMessage } = form.getFieldsValue();
    setLoading(true);
    try {
      await rescheduleBusinessApplication({
        bookingId: bookingId,
        collabId: collabId,
        additionalMessage: fieldMessage,
      });
      setMessageSelected(false);
      message.success("Application rescheduled successfully");
      setShowReschedule(false);
      navigate(".", { replace: true });
    } catch (error) {
      message.error("Error rescheduling application");
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div>
      <span
        onClick={() => setShowReschedule(true)}
        style={{ cursor: "pointer" }}
      >
        <RescheduleIcon /> Reschedule
      </span>
      <Modal
        open={showReschedule}
        title={title}
        onCancel={() => setShowReschedule(false)}
        footer={null}
      >
        <Typography.Text
          style={{ color: "#999999" }}
          hidden={dateSelect || messageSelected}
        >
          Suggest a specific date and time to the influencer or send them a
          message
        </Typography.Text>
        {dateSelect && (
          <FormDateSuggestions
            form={form}
            loading={loading}
            onSubmit={onFinishFormDateSuggestions}
            onCancel={() => setDateSelect(false)}
          />
        )}

        {messageSelected && (
          <FormMessage
            form={form}
            loading={loading}
            onSubmit={onFinishFormMessage}
            onCancel={() => setMessageSelected(false)}
          />
        )}

        {/* <Input.TextArea
          style={{ display: "none" }}
          placeholder="Message"
          value={additionalMessage}
          onChange={(evt) => setAdditionalMessage(evt.target.value)}
        /> */}
        {!dateSelect && !messageSelected && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Button
              style={{ justifySelf: "flex-end" }}
              onClick={() => setDateSelect(true)}
              type="primary"
              loading={loading}
            >
              Suggest another date
            </Button>
            <Button
              style={{ justifySelf: "flex-end" }}
              onClick={() => setMessageSelected(true)}
              loading={loading}
            >
              Send a message
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RescheduleRequest;
