import { Checkbox, Form, InputNumber, Typography } from "antd";
import { useWatch } from "antd/es/form/Form";
import SliderInput from "components/slider-input/SliderInput";

interface Props {
  // Define your component props here
}

const DiningCredits: React.FC<Props> = () => {
  const form = Form.useFormInstance();
  const averageCheckCost = useWatch("averageCheckCost", form);
  return (
    <div>
      <Typography.Title level={2}>Dining credits</Typography.Title>
      <Typography.Paragraph>
        Dining credits are not the amount we charge restaurants; they are what
        restaurants offer to influencers in the form of food barter. One credit
        is one dollar on the menu.
      </Typography.Paragraph>
      <Form.Item
        label="Your average check size"
        extra="Average amount spent by a customer at your restaurant"
        name="averageCheckCost"
        rules={[{ required: true }]}
      >
        <InputNumber min={0} max={1000} style={{ width: "50%" }} />
      </Form.Item>

      <Form.Item
        label="Food compensation"
        name="foodComp"
        rules={[{ required: true }]}
      >
        <SliderInput
          initialValue={75}
          max={Math.max(averageCheckCost * 2, 200)}
        />
      </Form.Item>

      <Form.Item name="guestAllowed" noStyle valuePropName="checked">
        <Checkbox>
          <b>+1 guest allowed</b>{" "}
          <span
            style={{
              color: "#999999",
            }}
          >
            {" "}
            (recommended)
          </span>
        </Checkbox>
      </Form.Item>
    </div>
  );
};

export default DiningCredits;
