import { searchBusinessApplications } from "services/SauceService";

export async function influencersLoader() {
  const businessId = localStorage.getItem("businessId");
  if (!businessId) {
    return [];
  }
  const response: any = await searchBusinessApplications({
    businessId,
    tabStatus: "pending",
  });
  return response;
}
