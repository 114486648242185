import { Form, FormInstance } from "antd";
import FieldDay from "./FieldDay";

interface FieldDayProps {
  form: FormInstance<any>;
  name: string;
}

const WeekHoursField: React.FC<FieldDayProps> = (props) => {
  const { form, name } = props;

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field: any, index: number) => {
            const workingHours = form.getFieldValue(name);
            const hour = workingHours[field.name];
            const dayOfWeek = hour.dayOfWeek;
            const canAdd =
              workingHours
                .map(({ dayOfWeek }: any) => dayOfWeek)
                .indexOf(dayOfWeek) === field.name;

            const repeatedIndexesCurrentDayOfWeek = workingHours.reduce(
              (acc: number[], current: any, index: number) => {
                if (current.dayOfWeek === dayOfWeek && index !== field.name)
                  acc.push(index);
                return acc;
              },
              []
            );
            return (
              <div key={`${name}_${dayOfWeek}_${field.name}_${index}`}>
                <FieldDay
                  field={field}
                  canAdd={canAdd}
                  onAdd={() => add({ ...hour }, field.name + 1)}
                  onRemove={() => remove(field.name)}
                  name={name}
                  onRemoveChildren={() =>
                    remove(repeatedIndexesCurrentDayOfWeek)
                  }
                  form={form}
                />
              </div>
            );
          })}
        </>
      )}
    </Form.List>
  );
};

export default WeekHoursField;
