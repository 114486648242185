import React, { useEffect, useState } from "react";
import "./CustomSlider.scss";

interface CustomSliderProps {
  initialValue: number;
  max: number;
  minValue: number; // Adiciona a propriedade minValue
  labelStep: number;
  sliderStep?: number;
  onChange: (value: number) => void;
}

const CustomSlider: React.FC<CustomSliderProps> = ({
  initialValue,
  max,
  minValue,
  labelStep,
  sliderStep = 1,
  onChange,
}) => {
  const [value, setValue] = useState<number>(Math.max(initialValue, minValue));
  const minimumStep = max / 4; //minValue <= labelStep ? labelStep / 2 : labelStep;

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Math.max(parseInt(event.target.value, 10), minValue);
    setValue(newValue);
    onChange(newValue);
  };

  console.log(minimumStep);
  console.log(minValue);

  const fillStyle = {
    width: `${((value - minValue) / (max - minValue)) * 100}%`,
  };

  const marks = Array.from(
    { length: Math.floor((max - minValue) / minimumStep) + 1 },
    (_, index) => minValue + index * minimumStep
  );

  console.log(minValue, max, minimumStep, sliderStep, marks);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="slider-container">
      <input
        type="range"
        min={minValue}
        max={max}
        step={sliderStep}
        value={value}
        onChange={handleSliderChange}
        className="range-slider"
      />
      <div className="slider-track">
        <div className="slider-fill" style={fillStyle}></div>
        <div className="slider-thumb" style={{ left: fillStyle.width }}></div>
      </div>
      <div className="slider-marks">
        {marks.map((mark) => (
          <span
            key={mark}
            style={{ left: `${((mark - minValue) / (max - minValue)) * 100}%` }}
          >
            {mark}
          </span>
        ))}
      </div>
    </div>
  );
};

export default CustomSlider;
