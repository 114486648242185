import {
  fetchCollabs,
  getCollabsStats,
  getCollabsStatsByDate,
  getSocialMediaStats,
} from "services/SauceService";

export async function reportLoader(props: any) {
  const url = new URL(props.request.url);
  const businessId = url.searchParams.get("businessId");
  const selectedMonth = url.searchParams.get("selectedMonth");
  const locationId = url.searchParams.get("locationId");

  const collabIds = url.searchParams.getAll("collabId");

  const params = {
    collabIds,
  };
  const paramsByBusiness = {
    businessId: businessId,
  };

  const [reportInfo, comments, socialMediaStats, collabsByMonth]: any =
    await Promise.all([
      getCollabsStats(collabIds.length ? params : paramsByBusiness),
      getCollabsStatsByDate(collabIds.length ? params : paramsByBusiness),
      getSocialMediaStats(paramsByBusiness),
      fetchCollabs({
        businessId,
        monthly: true,
      }),
    ]);

  const collabsIdsByMonths = Object.keys(collabsByMonth).reduce(
    (acc: any, key) => {
      acc[key] = [
        ...collabsByMonth[key].open.map((collab: any) => ({
          id: collab.collabId,
          venue: collab.venue.uuid,
        })),
        ...collabsByMonth[key].closed.map((collab: any) => ({
          id: collab.collabId,
          venue: collab.venue.uuid,
        })),
      ];
      return acc;
    },
    {}
  );

  return {
    ...reportInfo,
    ...comments,
    ...socialMediaStats,
    businessId,
    collabsIdsByMonths,
    selectedMonth,
    locationId,
  };
}
