import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchPendingCollabsCount } from "services/SauceService";

export const getPendingCollabsCount = createAsyncThunk(
  "collabs/getPendingCount",
  async () => {
    const businessId = localStorage.getItem("businessId");
    if (!businessId) {
      return {};
    }
    const pendingCollabsCount: any = await fetchPendingCollabsCount({
      businessId,
      tabStatus: "pending",
    });
    return pendingCollabsCount.total;
  }
);

export const collabsSlice: any = createSlice({
  name: "collabs",
  initialState: {
    loading: false,
    pendingCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPendingCollabsCount.fulfilled, (state, action) => {
      if (!isNaN(action.payload)) state.pendingCount = action.payload;
      state.loading = false;
    });

    builder.addCase(getPendingCollabsCount.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPendingCollabsCount.pending, (state) => {
      state.loading = true;
    });
  },
});

export default collabsSlice.reducer;
