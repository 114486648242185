import { Form, Select, Switch, TimePicker } from "antd";
import { ReactComponent as CloseIcon } from "assets/images/trash-icon.svg";
import { PlusCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useWatch } from "antd/es/form/Form";
import { isMobile } from "react-device-detect";

const optionsWeekDay = [
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
  { value: "sunday", label: "Sunday" },
];

const FieldDay: React.FC<any> = (props) => {
  const { name, field, canAdd, onAdd, onRemove, onRemoveChildren } = props;
  const form = Form.useFormInstance();

  const open = useWatch([name, field.name, "open"], {
    form,
  });
  const enabled = open !== false;
  const onChangeCheck = (checked: boolean) => {
    if (!checked) {
      onRemoveChildren();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 16,
        marginBottom: 12,
      }}
      key={`FIELD_DAY_${field.name}`}
    >
      <div
        style={{
          display: "flex",
          flex: isMobile ? "none" : 2,
          alignItems: "center",
        }}
      >
        {canAdd && (
          <>
            <Form.Item
              key={`FIELD_DAY_OPEN_${field.name}`}
              hidden={!canAdd}
              noStyle
              {...field}
              name={[field.name, "open"]}
              valuePropName="checked"
            >
              <Switch onChange={onChangeCheck} />
            </Form.Item>
            <Form.Item
              noStyle
              {...field}
              name={[field.name, "dayOfWeek"]}
              key={`FIELD_DAY_DAY_OF_WEEK_${field.name}`}
            >
              <Select
                variant="borderless"
                suffixIcon={null}
                options={optionsWeekDay}
                open={false}
                style={{ cursor: "default" }}
              />
            </Form.Item>
          </>
        )}
      </div>
      {enabled ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: isMobile ? "none" : 4,
            gap: 12,
          }}
        >
          <Form.Item
            noStyle
            {...field}
            name={[field.name, "startTime"]}
            key={`START_TIME_${field.name}`}
            getValueProps={(i) => {
              if (dayjs(i, "hh:mm A").isValid())
                return { value: dayjs(i, "hh:mm A") };
              if (dayjs(i, "hh:mm ").isValid())
                return { value: dayjs(i, "hh:mm") };
              return {};
            }}
            getValueFromEvent={(onChange) =>
              dayjs(onChange.$d).format("hh:mm A")
            }
          >
            <TimePicker format="hh:mm A" use12Hours needConfirm={false} />
          </Form.Item>

          <Form.Item
            noStyle
            {...field}
            key={`END_TIME_${field.name}`}
            name={[field.name, "endTime"]}
            getValueProps={(i) => {
              if (dayjs(i, "hh:mm A").isValid())
                return { value: dayjs(i, "hh:mm A") };
              if (dayjs(i, "hh:mm ").isValid())
                return { value: dayjs(i, "hh:mm") };
              return {};
            }}
            getValueFromEvent={(onChange) =>
              dayjs(onChange.$d).format("hh:mm A")
            }
          >
            {/* https://github.com/ant-design/ant-design/issues/21189 */}
            <TimePicker format="hh:mm A" use12Hours needConfirm={false} />
          </Form.Item>
          {canAdd && <PlusCircleOutlined onClick={onAdd} />}
          {!canAdd && (
            <CloseIcon style={{ cursor: "pointer" }} onClick={onRemove} />
          )}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "#F5F5F5",
            display: "flex",
            justifyContent: "center",
            flex: isMobile ? 1 : 4,
            borderRadius: 6,
            padding: "14px 0",
          }}
        >
          <span style={{ color: "#999999" }}>Closed</span>
        </div>
      )}
    </div>
  );
};

export default FieldDay;
