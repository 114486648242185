import { searchBusinessApplicationsInbox } from "services/SauceService";

export async function applicationsLoader() {
  const businessId = localStorage.getItem("businessId");
  if (!businessId) {
    return { today: [] };
  }
  try {
    const response: any = await searchBusinessApplicationsInbox({ businessId });
    const { Today, ...rest } = response;
    return { today: Today, ...rest };
  } catch (error) {
    return [];
  }
}
