import { Outlet, useLocation } from "react-router-dom";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getBusinessInfo,
  getBusinessSubscription,
  getBusinessess,
} from "redux-context/business";
import { autenticado } from "helpers/auth-functions";
import { getLoggedUserInfo } from "redux-context/user";
import { getPendingCollabsCount } from "redux-context/collabs";

// import * as rdd from "react-device-detect";

// (rdd as any).isMobile = true;
// (rdd as any).isDesktop = false;

function App() {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const { loading } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (autenticado() && loading) {
      dispatch(getLoggedUserInfo());
    }

    if (!loading) {
      dispatch(getBusinessess());
      dispatch(getBusinessInfo());
      dispatch(getBusinessSubscription());
      dispatch(getPendingCollabsCount());
    }
  }, [dispatch, loading]);

  useEffect(() => {
    dispatch(getBusinessSubscription());
    dispatch(getPendingCollabsCount());
  }, [location, dispatch]);

  return (
    <>
      <Outlet />
    </>
  );
}

export default App;
