import { Form, Input, message, Select, Typography } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  getPlaceById,
  getPlacesByName,
  retrieveUserSocialMedia,
} from "services/SauceService";
import { ReactComponent as CloseIcon } from "assets/images/trash-icon.svg";
import { formatNumberKMBT } from "pages/influencers/InfluencerCard";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";
import { ReactComponent as TikTokIcon } from "assets/images/tiktok-icon.svg";

interface RestaurantInfoProps {
  setSubmitBlocked: (value: boolean) => void;
  setLocationInfo: (value: any) => void;
  locationInfo: any;
}

enum SocialMedia {
  INSTAGRAM = "Instagram",
  TIKTOK = "Tiktok",
}

interface SocialMediaInfo {
  followers: number;
  name: string;
  profileImage: string;
  username: string;
  bio: string;
  postsCount: number;
  followings: number;
}

const RestaurantInfo: React.FC<RestaurantInfoProps> = (props) => {
  const { setSubmitBlocked, setLocationInfo, locationInfo } = props;
  const form = Form.useFormInstance();
  const [loading, setLoading] = useState<boolean>(false);
  const [showTiktok, setShowTiktok] = useState<boolean>(false);
  const [places, setPlaces] = useState<any[]>([]);
  const [instagramInfo, setInstagramInfo] = useState<SocialMediaInfo>();
  const [instagramError, setInstagramError] = useState<boolean>(false);
  const [tiktokInfo, setTiktokInfo] = useState<SocialMediaInfo>();

  const getLocation = useCallback(async (locationName: string) => {
    setLoading(true);
    const response: any = await getPlacesByName({ name: locationName });
    setPlaces(
      response.map((place: any) => ({
        value: place.place_id,
        label: place.name,
        ...place,
      }))
    );
    setLoading(false);
  }, []);

  const handleSearch = (newValue: string) => {
    if (newValue) getLocation(newValue);
  };

  const handleChange = async (_: string, option: any) => {
    const response: any = await getPlaceById(option.place_id);

    const allowedCoutnries = ["US", "CA"];

    if (!allowedCoutnries.includes(response?.countryCode)) {
      form.setFieldValue("yourBusiness", undefined);
      message.error("Only US and Canada are allowed");
      return;
    }

    form.setFieldValue("placeAddress", option?.formatted_address);
    form.setFieldValue("placeName", option?.name);
    form.setFieldValue("placeLatitude", option?.geometry?.location?.lat);
    form.setFieldValue("placeLongitude", option?.geometry?.location?.lng);
    setLocationInfo(option);
  };

  const onChangeInstagram = (evt: any) => {
    if (evt.target.value)
      onSearchSocial(evt.target.value, SocialMedia.INSTAGRAM);
  };

  const onChangeTiktok = (evt: any) => {
    if (evt.target.value) onSearchSocial(evt.target.value, SocialMedia.TIKTOK);
  };

  const onSearchSocial = async (value: string, socialMedia: SocialMedia) => {
    const clearField = () => {
      if (socialMedia === SocialMedia.INSTAGRAM)
        form.setFieldValue("instagramUserName", "");
      if (socialMedia === SocialMedia.TIKTOK)
        form.setFieldValue("tikTokUserName", "");
    };
    const clearInfo = () => {
      if (socialMedia === SocialMedia.INSTAGRAM) setInstagramInfo(undefined);
      if (socialMedia === SocialMedia.TIKTOK) setTiktokInfo(undefined);
    };

    const currentValue =
      socialMedia === SocialMedia.INSTAGRAM ? instagramInfo : tiktokInfo;
    if (value && value !== currentValue?.username) {
      setSubmitBlocked(true);
      clearInfo();
      const params = {
        username: value,
        socialMedia,
      };
      try {
        const response: any = await retrieveUserSocialMedia(params);
        console.log(response);
        if (response?.profileImage) {
          setInstagramError(false);
          if (socialMedia === SocialMedia.INSTAGRAM) {
            setInstagramInfo(response);
            form.setFieldValue("instagramUserName", response.username);
          }
          if (socialMedia === SocialMedia.TIKTOK) {
            setTiktokInfo(response);
            form.setFieldValue("tikTokUserName", response.username);
          }
        } else {
          message.error("Account not found, try again");
          setInstagramError(true);
          clearField();
        }
      } catch (error) {
        message.error("An error occurred, try again later");
        setInstagramError(true);
        clearField();
      }
    }
    setSubmitBlocked(false);
  };
  console.log(instagramError);
  const onClickRemoveTiktok = () => {
    setShowTiktok(true);
    form.setFieldValue("tiktok", undefined);
    setTiktokInfo(undefined);
  };

  const filterListDoubleOrEmpty = (
    list: { label: string; value: any }[],
    item: { label: string; value: any }
  ) => {
    if (!item) return list;

    return [...list, item].filter(
      (item, index, self) =>
        item.value !== undefined &&
        index === self.findIndex((t) => t.label === item.label)
    );
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Typography.Title level={2}>Restaurant info</Typography.Title>

      <Form.Item name="placeId" rules={[{ required: true }]}>
        <Select
          placeholder="Find your business"
          showSearch
          filterOption={false}
          onSearch={debounce(handleSearch, 500)}
          onSelect={handleChange}
          options={filterListDoubleOrEmpty(places, locationInfo)}
          loading={loading}
          allowClear
          optionRender={(option: any) => {
            if (option.data)
              return (
                <>
                  <div style={{ padding: "8px", cursor: "pointer" }}>
                    {`${option.data.name} - ${option.data.formatted_address}`}
                  </div>
                </>
              );
            return option?.label;
          }}
        />
      </Form.Item>
      <Form.Item
        name="instagramUserName"
        validateStatus={instagramError ? "error" : ""}
        help={instagramError ? "Instagram account is required" : ""}
        rules={[{ required: true }]}
      >
        <Input
          placeholder="Instagram account"
          onChange={debounce(onChangeInstagram, 2000)}
        />
      </Form.Item>
      {instagramInfo && (
        <div className="box-social">
          <img src={instagramInfo.profileImage} alt="profile" />
          <div>
            <div className="content-social">
              <InstagramIcon />
              <span className="title bold">{instagramInfo.username}</span>
              <span className="title">{instagramInfo.name}</span>
            </div>
            <div className="content-social">
              <div className="content-social">
                <span className="title bold">
                  {formatNumberKMBT(instagramInfo.followings)}
                </span>
                <span className="title">following</span>
              </div>
              <div className="content-social">
                <span className="title bold">
                  {formatNumberKMBT(instagramInfo.followers)}
                </span>
                <span className="title">followers</span>
              </div>
              <div className="content-social">
                <span className="title bold">
                  {formatNumberKMBT(instagramInfo.postsCount)}
                </span>
                <span className="title">posts</span>
              </div>
            </div>
            <div>
              <p>{instagramInfo.bio}</p>
            </div>
          </div>
        </div>
      )}

      {showTiktok && (
        <>
          <div className="social-media-input">
            <Form.Item name="tikTokUserName">
              <Input
                placeholder="Tiktok account"
                onChange={debounce(onChangeTiktok, 2000)}
              />
            </Form.Item>
            <CloseIcon onClick={() => setShowTiktok(false)} />
          </div>
          {tiktokInfo && (
            <div className="box-social">
              <img src={tiktokInfo.profileImage} alt="profile" />
              <div>
                <div className="content-social">
                  <TikTokIcon />
                  <span className="title bold">{tiktokInfo.username}</span>
                  <span className="title">{tiktokInfo.name}</span>
                </div>
                <div className="content-social">
                  <div className="content-social">
                    <span className="title bold">
                      {formatNumberKMBT(tiktokInfo.followings)}
                    </span>
                    <span className="title">following</span>
                  </div>
                  <div className="content-social">
                    <span className="title bold">
                      {formatNumberKMBT(tiktokInfo.followers)}
                    </span>
                    <span className="title">followers</span>
                  </div>
                  <div className="content-social">
                    <span className="title bold">
                      {formatNumberKMBT(tiktokInfo.postsCount)}
                    </span>
                    <span className="title">posts</span>
                  </div>
                </div>
                <div>
                  <p>{tiktokInfo.bio}</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {!showTiktok && (
        <div>
          Add another channel:{" "}
          {!showTiktok && (
            <span className="underline-span" onClick={onClickRemoveTiktok}>
              Tiktok
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default RestaurantInfo;
