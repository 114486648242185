import { useState } from "react";
import { Button, ConfigProvider, Form, Progress } from "antd";
import { isDesktop, isTablet } from "react-device-detect";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import StartBg from "assets/images/onboarding/start.png";
import RestaurantInfoBg from "assets/images/onboarding/restaurant-info.png";
import BusinessGoalBg from "assets/images/onboarding/business-goal.png";
import DiningCreditsBg from "assets/images/onboarding/dining-credits.png";
import WhenVisitingInfluencersBg from "assets/images/onboarding/when-visiting-influencers.png";
import WhatExpect from "assets/images/onboarding/what-expect.png";
import OnboardingStart from "./OnboardingStart";
import RestaurantInfo from "./RestaurantInfo";
import BusinessGoal from "./BusinessGoal";
import DiningCredits from "./DiningCredits";
import OpeningHours from "./OpeningHours";
import venueWorkingHours from "pages/collab/weekHoursDefault.json";
import Expectations from "./Expectations";
import "./Onboarding.scss";
import { postPartialSaveOnboarding } from "services/MakeService";
import { postFinishOnboarding } from "services/SauceService";

interface Props {
  // Define your component's props here
}
enum StepsOnboarding {
  START = 0,
  RESTAURANT_INFO = 1,
  BUSINESS_GOAL = 2,
  DINING_CREDITS = 3,
  WHEN_VISITING_INFLUENCERS = 4,
  WHAT_EXPECT = 5,
}

const imageByStep = {
  [StepsOnboarding.START]: StartBg,
  [StepsOnboarding.RESTAURANT_INFO]: RestaurantInfoBg,
  [StepsOnboarding.BUSINESS_GOAL]: BusinessGoalBg,
  [StepsOnboarding.DINING_CREDITS]: DiningCreditsBg,
  [StepsOnboarding.WHEN_VISITING_INFLUENCERS]: WhenVisitingInfluencersBg,
  [StepsOnboarding.WHAT_EXPECT]: WhatExpect,
};

const StepsEnumLength = Object.keys(StepsOnboarding).length / 2;
const Onboarding: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState<StepsOnboarding>(
    StepsOnboarding.START
  );
  const [submitBlocked, setSubmitBlocked] = useState<boolean>(false);
  const [locationInfo, setLocationInfo] = useState<any>();

  const webOrientation = isDesktop || isTablet;
  const percentageByStep = (100 / StepsEnumLength) * (currentStep + 1);

  const onClickNext = async () => {
    if (currentStep === StepsEnumLength - 1) {
      form.submit();
      return;
    }
    try {
      if (await form.validateFields()) {
        console.log("valid");
        if (currentStep <= StepsOnboarding.RESTAURANT_INFO) {
          const values = form.getFieldsValue(true);
          postPartialSaveOnboarding(values);
        }
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      console.log("Failed:", error);
    }
  };

  const onClickBack = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const onFinishForm = async () => {
    const values = form.getFieldsValue(true);
    const response: any = await postFinishOnboarding(values);
    console.log(response);
    if (response?.accessToken) {
      localStorage.setItem("token", response.accessToken);
      window.location.href = "/";
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Switch: {
            controlHeight: 24,
            colorPrimary: "#ffc261",
            handleSize: 20,
            trackHeight: 24,
            trackMinWidth: 40,
            innerMaxMargin: 12,
          },
        },
      }}
    >
      <div className={`onboarding ${webOrientation ? "web" : ""}`}>
        <div
          className="content"
          // style={{ minHeight: `${windowHeight}px`, transition: "height 0.3s" }}
        >
          <div>
            <Logo />
            <Progress
              trailColor="#FFF"
              strokeColor="#F58F33"
              percent={percentageByStep}
              showInfo={false}
            />
            <Form
              form={form}
              onFinish={onFinishForm}
              layout="vertical"
              initialValues={{
                venueWorkingHours,
                averageCheckCost: 100,
                foodComp: 75,
              }}
            >
              {currentStep === StepsOnboarding.START && <OnboardingStart />}
              {currentStep === StepsOnboarding.RESTAURANT_INFO && (
                <RestaurantInfo
                  setSubmitBlocked={setSubmitBlocked}
                  setLocationInfo={setLocationInfo}
                  locationInfo={locationInfo}
                />
              )}
              {currentStep === StepsOnboarding.BUSINESS_GOAL && (
                <BusinessGoal />
              )}
              {currentStep === StepsOnboarding.DINING_CREDITS && (
                <DiningCredits />
              )}
              {currentStep === StepsOnboarding.WHEN_VISITING_INFLUENCERS && (
                <OpeningHours />
              )}
              {currentStep === StepsOnboarding.WHAT_EXPECT && <Expectations />}
            </Form>
          </div>
          <div className="buttons">
            <Button
              type="text"
              onClick={onClickBack}
              style={{
                visibility:
                  currentStep === StepsOnboarding.START ? "hidden" : "initial",
              }}
            >
              Back
            </Button>
            <Button
              type="primary"
              onClick={onClickNext}
              disabled={submitBlocked}
              style={{
                display:
                  currentStep === StepsEnumLength - 1 ? "none" : "initial",
              }}
            >
              Next
            </Button>
            <Button
              type="primary"
              onClick={onClickNext}
              style={{
                display:
                  currentStep === StepsEnumLength - 1 ? "initial" : "none",
              }}
            >
              Let's start!
            </Button>
          </div>
        </div>
        <div
          className="bg-images"
          style={{
            display: webOrientation ? "flex" : "none",
            backgroundImage: `url(${imageByStep[currentStep]})`,
          }}
        />
      </div>
    </ConfigProvider>
  );
};

export default Onboarding;
