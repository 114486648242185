import dayjs from "dayjs";
import { Card, Col, Divider, Row, Spin, Typography } from "antd";
import { useSelector } from "react-redux";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { ReactComponent as UserIcon } from "assets/images/user-icon.svg";
import { ReactComponent as CrownIcon } from "assets/images/crown-icon.svg";
import { ReactComponent as DownloadIcon } from "assets/images/download-icon.svg";
import FomoPromo from "assets/images/fomo-promo-image.png";
import { moneyFormat } from "helpers/money-format";
import "./Settings.scss";
import { Subscription } from "interfaces/subscription";
import { InfluencerType } from "enums/InfluencerType";

const SubscriptionPage: React.FC = () => {
  const {
    subscription,
    loading,
  }: { subscription: Subscription; loading: boolean } = useSelector(
    (state: any) => state.business
  );

  const nextPaymentDate = subscription?.stripeDetails?.nextPaymentDetails
    ? dayjs
        .utc(subscription?.stripeDetails?.nextPaymentDetails?.date)
        .format("MMM DD, YYYY")
    : "";

  const nextPaymentAmount = subscription?.stripeDetails?.nextPaymentDetails
    ? moneyFormat(
        subscription?.stripeDetails?.nextPaymentDetails?.amount,
        subscription?.stripeDetails?.nextPaymentDetails?.currency
      )
    : "";

  const paymentDetailCardInfo = `${subscription?.stripeDetails?.defaultPaymentMethod?.brand?.toUpperCase()} ∘∘∘∘ ${
    subscription?.stripeDetails?.defaultPaymentMethod?.last4
  }`;
  const paymentDetailCardExp = subscription?.stripeDetails?.defaultPaymentMethod
    ?.expMonth
    ? `${subscription?.stripeDetails?.defaultPaymentMethod?.expMonth}/${subscription?.stripeDetails?.defaultPaymentMethod?.expYear}`
    : "";

  const basicRemaining =
    subscription?.spotsUsageByType?.find(
      (item) => item.type === InfluencerType.basic
    )?.amountRemaining || 0;

  const premiumRemaining =
    subscription?.spotsUsageByType?.find(
      (item) => item.type === InfluencerType.premium
    )?.amountRemaining || 0;

  const onClickDownloadInvoice = (invoice: { invoicePdf: string }) => {
    window.open(invoice.invoicePdf, "_blank", "noopener");
  };

  if (loading) return <Spin />;

  return (
    <div className="subscription">
      <Typography.Title level={3}>Current plan</Typography.Title>
      <Card loading={loading}>
        <Row justify="center" gutter={[12, 12]}>
          <Col xs={24} md={12}>
            <div style={{ display: "flex", gap: 20 }}>
              <img src={FomoPromo} alt="Fomo promo" />
              <div>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {subscription?.planName}
                </Typography.Title>
                <Typography.Text>Current plan</Typography.Text>
              </div>
            </div>
          </Col>
          <Col xs={24} md={4}>
            <div className="box-info">
              <span>
                <UserIcon /> Basic influencers
              </span>
              <Typography.Text
                style={{ marginLeft: 16 }}
              >{`${basicRemaining} out of ${subscription?.basicAllowed}`}</Typography.Text>
            </div>
          </Col>
          <Col xs={24} md={4}>
            <div className="box-info">
              <span>
                <CrownIcon /> Premium influencers
              </span>
              <Typography.Text
                style={{ marginLeft: 16 }}
              >{`${premiumRemaining} out of ${subscription?.premiumAllowed}`}</Typography.Text>
            </div>
          </Col>
          <Col xs={24} md={4}>
            <div className="box-info">
              <span>
                <LocationIcon /> Locations
              </span>
              <Typography.Text
                style={{ marginLeft: 16 }}
              >{`${subscription?.locationsUsage} out of ${subscription?.locationsAllowed}`}</Typography.Text>
            </div>
          </Col>
        </Row>
        {subscription?.stripeDetails?.nextPaymentDetails && (
          <>
            <Divider style={{ borderColor: "#FFAC39", borderWidth: 2 }} />
            <Row>
              <Col xs={12}>
                <Row style={{ gap: 48 }}>
                  <div className="box-info">
                    <span>Next payment date</span>
                    <Typography.Text>{nextPaymentDate}</Typography.Text>
                  </div>
                  <div className="box-info">
                    <span>Total</span>
                    <Typography.Text>{nextPaymentAmount}/mo</Typography.Text>
                  </div>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Card>
      {subscription?.stripeDetails && (
        <>
          <Typography.Title level={3}>Payment methods</Typography.Title>
          <Card>
            <Row>
              <Typography.Text>{paymentDetailCardInfo}</Typography.Text>
              <span className="payment-exp" style={{ marginLeft: 20 }}>
                {paymentDetailCardExp}
              </span>
            </Row>
          </Card>
          <Typography.Title level={3}>Receipts</Typography.Title>
          {subscription?.stripeDetails?.invoices.map((invoice) => (
            <Card key={invoice.id}>
              <Row>
                <Col xs={6}>
                  <Typography.Text>
                    {dayjs.utc(invoice.date || "").format("MMM DD, YYYY")}
                  </Typography.Text>
                </Col>
                <Col xs={6}>
                  <Typography.Text>{invoice?.description}</Typography.Text>
                </Col>
                <Col xs={12} className="invoice-format">
                  <Typography.Text>
                    {moneyFormat(invoice.amount, invoice.currency)}{" "}
                    {invoice?.currency?.toUpperCase()}
                  </Typography.Text>
                  <DownloadIcon
                    onClick={() => onClickDownloadInvoice(invoice)}
                  />
                </Col>
              </Row>
            </Card>
          ))}
        </>
      )}
    </div>
  );
};

export default SubscriptionPage;
