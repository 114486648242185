import { Form, Switch, Typography } from "antd";
import WeekHoursField from "pages/collab/WeekHoursField";
import React from "react";

interface Props {
  // Define your component props here
}

const OpeningHours: React.FC<Props> = () => {
  const form = Form.useFormInstance();
  const ghostKitchen = Form.useWatch("ghostKitchen", form);
  console.log(ghostKitchen);
  return (
    <div>
      <Typography.Title level={2}>
        When should influencers be visiting your place?
      </Typography.Title>
      <Form.Item
        name="ghostKitchen"
        label="I'm an online business"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      {!ghostKitchen && <WeekHoursField name="venueWorkingHours" form={form} />}
    </div>
  );
};

export default OpeningHours;
