import { useState } from "react";
import { InputNumber } from "antd";
import CustomSlider from "./CustomSlider";
import "./SliderInput.scss";

interface SliderInputProps {
  initialValue: number;
  max: number;
  onChange?: (value: number) => void;
}

const SliderInput: React.FC<SliderInputProps> = ({
  initialValue,
  max,
  onChange,
}) => {
  const [value, setValue] = useState<number>(initialValue);
  const minValue = 0;

  return (
    <div className="slider-input-container">
      <InputNumber
        min={minValue}
        max={max}
        value={value}
        onChange={(newValue) => {
          if (typeof newValue === "number") {
            setValue(newValue);
            if (onChange) onChange(newValue);
          }
        }}
        style={{ width: "100px", marginRight: "10px" }}
      />
      <CustomSlider
        sliderStep={1}
        labelStep={50}
        initialValue={value}
        minValue={minValue}
        max={max}
        onChange={(newValue) => {
          setValue(newValue);
          if (onChange) onChange(newValue);
        }}
      />
    </div>
  );
};

export default SliderInput;
