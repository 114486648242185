import {
  getCollabById,
  searchBusinessApplications,
  searchInvitedBusiness,
} from "services/SauceService";

export async function collabLoader(props: any) {
  const collab: any = await getCollabById(props.params.id);

  const accepted: any[] = [];
  const pending: any[] = [];
  const invited: any[] = [];
  const rejected: any[] = [];
  const expired: any[] = [];

  if (collab.collabId) {
    const businessId = localStorage.getItem("businessId");
    const response: any = await searchBusinessApplications({
      collabId: collab.collabId,
      businessId,
    });
    accepted.push(
      ...response.reduce((acc: any[], item: any) => {
        if (item.tabStatus === "accepted") {
          acc.push(item);
        } else if (item.tabStatus === "pending") {
          pending.push(item);
        } else if (item.tabStatus === "rejected") {
          rejected.push(item);
        } else if (item.tabStatus === "expired") {
          expired.push(item);
        }

        return acc;
      }, [])
    );

    const invitedList: any = await searchInvitedBusiness({
      collabId: collab.collabId,
    });

    invited.push(...invitedList);
  }

  return { collab, accepted, pending, invited, rejected, expired };
}
