import { Typography } from "antd";
import mock from "assets/images/messages-screen.png";

const Messages: React.FC = () => {
  return (
    <div>
      <Typography.Title>Messages</Typography.Title>

      <img alt="" src={mock} style={{ width: "100%" }} />
    </div>
  );
};

export default Messages;
