import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Segmented,
  Select,
  Spin,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import VenueNotFound from "assets/images/venue-not-found.png";
import { Collab } from "interfaces/collab";
import { CrownOutlined, RightOutlined } from "@ant-design/icons";
import { ReactComponent as MessagesIcon } from "assets/images/message-text-icon.svg";
import { ReactComponent as FlashIcon } from "assets/images/flash-icon.svg";
import { ReactComponent as HeartIcon } from "assets/images/heart-icon.svg";
import { ReactComponent as PlayIcon } from "assets/images/play-icon.svg";
import { ReactComponent as RepeatIcon } from "assets/images/repeat-icon.svg";
import { ReactComponent as CollabsEmpty } from "assets/images/collabs-empty.svg";
import { ReactComponent as PurchaseIcon } from "assets/images/profile-icon.svg";
import { ReactComponent as ReachIcon } from "assets/images/reach-icon.svg";
import { useSelector } from "react-redux";
import "./Collabs.scss";
import { formatNumberKMBT } from "pages/influencers/InfluencerCard";
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";

enum CollabStatus {
  OPEN = "Open",
  Completed = "Completed",
}
const monthNames = [
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
];
export const AvatarWithErrorHandling: React.FC<any> = ({ src, ...rest }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
    return true;
  };

  return imgError ? (
    <Avatar src={VenueNotFound} {...rest} />
  ) : (
    <Avatar src={src} onError={handleError} {...rest} />
  );
};

const EmptyList = () => {
  const navigate = useNavigate();
  const onClickCreateCollab = () => {
    navigate("/collabs/new");
  };
  return (
    <div>
      <Card>
        <Row justify="center">
          <Col md={12} xs={24} style={{ textAlign: "center" }}>
            <Row justify="center">
              <CollabsEmpty />
            </Row>
            <Typography.Title level={3}>No active collabs yet</Typography.Title>
            <Typography.Text>
              You currently don't have any active collabs
            </Typography.Text>
            <Row justify="center" style={{ marginTop: 42 }}>
              <Button type="primary" onClick={onClickCreateCollab}>
                Create collab
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const CollabCard: React.FC<any> = ({ collab }: any) => {
  const navigate = useNavigate();
  if (isMobile)
    return (
      <Col
        xs={24}
        key={collab.collabId}
        onClick={() => navigate(`/collabs/${collab.collabId}`)}
      >
        <Card key={collab.collabId} style={{ borderRadius: 16 }}>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={7}>
              <span style={{ fontSize: 16, fontWeight: 500 }}>
                {collab?.venue?.name}
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                {collab?.venue?.address}
              </div>
            </Col>
            <Col xs={8} md={4}>
              <div style={{ display: "flex" }}>
                <div className="calendar">
                  <span className="month">
                    {dayjs.utc(collab.startDate || "").format("MMM")} {" ∘ "}
                    {dayjs.utc(collab.endDate || "").format("MMM")}
                  </span>
                  <span className="day">
                    {dayjs.utc(collab.endDate || "").format("DD")}
                  </span>
                </div>
                <AvatarWithErrorHandling
                  src={`https://s3.amazonaws.com/data.mustard.love.upload/venue/${collab?.venue?.uuid}_cover.png`}
                  shape="round"
                  size={60}
                  style={{ position: "relative", marginLeft: -16 }}
                />
              </div>
            </Col>
            <Col xs={16}>
              <Row>
                <Col xs={24} md={4}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 8,
                    }}
                  >
                    <b>{collab.premiumFoodiesNeeded} premiums slots</b>
                    <span className="diamond">
                      <CrownOutlined />
                    </span>
                  </span>
                  <div>
                    <span style={{}}>
                      {collab.premiumFoodiesApplied || 0} applied
                    </span>
                    {" ∘ "}
                    <span style={{}}>
                      {collab.premiumFoodiesConfirmed || 0} accepted
                    </span>
                  </div>
                </Col>
                <Col xs={24} md={4}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 8,
                    }}
                  >
                    <b>{collab.basicFoodiesNeeded} basics slots</b>
                  </span>
                  <div>
                    <span style={{}}>
                      {collab.basicFoodiesApplied || 0} applied
                    </span>
                    {" ∘ "}
                    <span style={{}}>
                      {collab.basicFoodiesConfirmed || 0} accepted
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={3}>
              <Row style={{ gap: 8 }}>
                {collab?.postCoverImgs.map((post: any, index: number) => (
                  <div key={index}>
                    <img src={post} alt="" className="image-post" />
                  </div>
                ))}
              </Row>
            </Col>
          </Row>
        </Card>
        {collab.postCoverImgs?.length ? (
          <div className="extra-info" style={{ gap: 4 }}>
            <div className="content">
              <span className="info">
                <PlayIcon />
                {collab?.viewsTotal || "-"}
              </span>
              <span className="info">
                <HeartIcon />
                {collab?.likesTotal || "-"}
              </span>
              <span className="info">
                <MessagesIcon />
                {collab?.commentsTotal || "-"}
              </span>
              <span className="info">
                <RepeatIcon />
                {collab?.reshareTotal || "-"}
              </span>
              <span className="info">
                <FlashIcon />
                {collab?.engagementRateTotal || "-"}%
              </span>
              <span className="info">
                <ReachIcon />
                {formatNumberKMBT(collab?.reachTotal) || "-"}
              </span>
              <span className="info">
                <PurchaseIcon />
                {collab?.dinnerTotal || "-"}
              </span>
            </div>
          </div>
        ) : null}
      </Col>
    );
  return (
    <Col xs={24} key={collab.collabId}>
      <Card key={collab.collabId} style={{ borderRadius: 16 }}>
        <Row>
          <Col xs={24} md={4}>
            <div style={{ display: "flex" }}>
              <div className="calendar">
                <span className="month">
                  {dayjs.utc(collab.startDate || "").format("MMM")} {" ∘ "}
                  {dayjs.utc(collab.endDate || "").format("MMM")}
                </span>
                <span className="day">
                  {dayjs.utc(collab.endDate || "").format("DD")}
                </span>
              </div>
              <AvatarWithErrorHandling
                src={`https://s3.amazonaws.com/data.mustard.love.upload/venue/${collab?.venue?.uuid}_cover.png`}
                shape="round"
                size={60}
                style={{ position: "relative", marginLeft: -16 }}
              />
            </div>
          </Col>
          <Col xs={24} md={7}>
            <span style={{ fontSize: 16, fontWeight: 500 }}>
              {collab?.venue?.name}
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}
            >
              {collab?.venue?.address}
            </div>
          </Col>
          <Col xs={24} md={4}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}
            >
              <b>{collab.premiumFoodiesNeeded} premiums slots</b>
              <span className="diamond">
                <CrownOutlined />
              </span>
            </span>
            <div>
              <span style={{}}>
                {collab.premiumFoodiesApplied || 0} applied
              </span>
              {" ∘ "}
              <span style={{}}>
                {collab.premiumFoodiesConfirmed || 0} accepted
              </span>
            </div>
          </Col>
          <Col xs={24} md={4}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}
            >
              <b>{collab.basicFoodiesNeeded} basics slots</b>
            </span>
            <div>
              <span style={{}}>{collab.basicFoodiesApplied || 0} applied</span>
              {" ∘ "}
              <span style={{}}>
                {collab.basicFoodiesConfirmed || 0} accepted
              </span>
            </div>
          </Col>
          <Col xs={24} md={3}>
            <Row style={{ gap: 8 }}>
              {collab?.postCoverImgs.map((post: any, index: number) => (
                <div key={index}>
                  <img src={post} alt="" className="image-post" />
                </div>
              ))}
            </Row>
          </Col>
          <Col xs={24} md={2}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <RightOutlined
                style={{ fontSize: 24 }}
                onClick={() => navigate(`/collabs/${collab.collabId}`)}
              />
            </div>
          </Col>
        </Row>
      </Card>
      {collab.postCoverImgs?.length ? (
        <div className="extra-info">
          <div className="content">
            <span className="info">
              <PlayIcon />
              {collab?.viewsTotal || "-"}
            </span>
            <span className="info">
              <HeartIcon />
              {collab?.likesTotal || "-"}
            </span>
            <span className="info">
              <MessagesIcon />
              {collab?.commentsTotal || "-"}
            </span>
            <span className="info">
              <RepeatIcon />
              {collab?.reshareTotal || "-"}
            </span>
          </div>
          <div
            className="content"
            style={{
              justifyContent: "flex-end",
            }}
          >
            <span className="info">
              <FlashIcon />
              {collab?.engagementRateTotal || "-"}% Eng
            </span>

            <span className="info">
              <ReachIcon />
              {formatNumberKMBT(collab?.reachTotal) || "-"} overall reach
            </span>

            <span className="info">
              <PurchaseIcon />
              {collab?.dinnerTotal || "-"} Purchase Intent
            </span>
          </div>
        </div>
      ) : null}
    </Col>
  );
};

const Collabs: React.FC = () => {
  const months = useLoaderData() as any;
  const navigation = useNavigation();
  const loading = navigation.state === "loading";
  const [selectedStatus, setSelectedStatus] = useState<string>(
    CollabStatus.OPEN
  );
  const [venueOptions, setVenueOptions] = useState<any>([
    { value: "all", label: "All Locations" },
  ]);
  const [selectedPeriod, setSelectedPeriod] = useState<string>("all");
  const [selectedLocation, setSelectedLocation] = useState<string>("all");
  const { business } = useSelector((state: any) => state.business);
  const orderedMonths = monthNames.filter((month) =>
    Object.keys(months).includes(month)
  );
  const onChangeStatus = (status: string) => {
    setSelectedStatus(status);
  };

  const filterByVenueAndStatus = (list: Collab[] = []) => {
    return list.filter((collab: Collab) => {
      const isUpcoming = selectedStatus === CollabStatus.OPEN;
      const isAllLocations = selectedLocation === "all";
      const isMatchingVenue = collab.venue.uuid === selectedLocation;

      if (isAllLocations || isMatchingVenue) {
        return isUpcoming
          ? collab.status === 4 || collab.status === 1
          : collab.status === 2 || collab.status === 3;
      }

      return false;
    });
  };

  const filterByPeriod = (list: string[] = []) => {
    if (selectedPeriod === "all") return list;
    return list.filter((month) => month === selectedPeriod);
  };

  useEffect(() => {
    if (business) {
      const businessVenue =
        business.venues?.map((venue: any) => ({
          value: venue.uuid,
          label: venue.name,
        })) || [];
      setVenueOptions([
        { value: "all", label: "All Locations" },
        ...businessVenue,
      ]);
    }
  }, [business]);

  if (orderedMonths.length === 0) return <EmptyList />;
  if (loading) return <Spin />;

  return (
    <div className="collabs">
      <Title>Collabs</Title>
      <Row justify="space-between">
        <Segmented
          options={["Open", "Completed"]}
          value={selectedStatus}
          onChange={onChangeStatus}
          style={{ marginBottom: 32 }}
        />
        <div>
          <Select
            variant="borderless"
            value={selectedPeriod}
            options={[
              {
                value: "all",
                label: "All periods",
              },
              ...orderedMonths.map((month) => ({
                value: month,
                label: month.charAt(0).toUpperCase() + month.slice(1),
              })),
            ]}
            onChange={(value) => setSelectedPeriod(value)}
          />
          <Select
            variant="borderless"
            value={selectedLocation}
            options={venueOptions}
            onChange={(value) => setSelectedLocation(value)}
            popupMatchSelectWidth={false}
          />
        </div>
      </Row>
      {filterByPeriod(orderedMonths).map((month: any) => {
        const currentMonth = months[month];
        const selectedList =
          selectedStatus === CollabStatus.OPEN
            ? currentMonth.open
            : currentMonth.closed;
        const filtered = filterByVenueAndStatus(selectedList);
        if (!months[month] || filtered.length === 0) return null;

        return (
          <Row
            key={currentMonth.month}
            style={{ marginBottom: 16 }}
            gutter={[32, 32]}
          >
            <Col xs={24}>
              <Typography.Title level={3}>
                {month.charAt(0).toUpperCase() + month.slice(1)}
              </Typography.Title>
            </Col>
            {filtered.map((collab: any) => (
              <CollabCard collab={collab} />
            ))}
          </Row>
        );
      })}
    </div>
  );
};

export default Collabs;
