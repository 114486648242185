import { Row, Typography } from "antd";
import ErrorBG from "assets/images/error-bg.png";

const NotFound: React.FC = () => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Typography.Title level={3}>
          Sorry we couldn't find the page you are looking for...
        </Typography.Title>
        <Row justify="center">
          <img
            src={ErrorBG}
            alt="Error"
            style={{ maxWidth: "100%", height: "auto", margin: "80px 0" }}
          />
        </Row>
      </div>
    </div>
  );
};

export default NotFound;
