import { configureStore } from "@reduxjs/toolkit";
import businessReducer from "./business";
import userReducer from "./user";
import collabsReducer from "./collabs";

export default configureStore({
  reducer: {
    business: businessReducer,
    user: userReducer,
    collabs: collabsReducer,
  },
});
