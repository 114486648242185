import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Modal, Row, Typography } from "antd";
import { ApexOptions } from "apexcharts";
import { formatNumberKMBT } from "pages/influencers/InfluencerCard";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const chartOptionsDefault: any = {
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    type: "area",
    parentHeightOffset: 0,
    sparkline: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  grid: {
    yaxis: {
      lines: {
        show: false,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
    row: {
      opacity: 0.5,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
};

interface CardGraphTrendProps {
  title: string;
  data: { value: any; percentage: number; data: any };
  dataProp: string;
  platform: string;
  username: string;
}
const CardGraphTrend: React.FC<CardGraphTrendProps> = (props) => {
  const { title, data, dataProp, platform, username } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);

  const negative = data.percentage && data.percentage < 0;

  const dataChart = {
    series: [
      {
        name: title,
        data: data.data.map((item: any) => item[dataProp]),
      },
    ],

    colors: negative ? ["#FF354A"] : ["#07B22D"],
    xaxis: {
      categories: data.data.map((item: any) => item.date),
    },
  };

  const overallReachData: ApexOptions = {
    ...chartOptionsDefault,

    ...dataChart,
  };

  const modalData: ApexOptions = {
    ...dataChart,
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      type: "area",
      parentHeightOffset: 0,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      row: {
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "datetime",
      // tickPlacement: "between",
      labels: {
        // rotate: -90,
        format: "MMM dd",
      },
      categories: data.data.map((item: any) => item.date),
    },
    yaxis: {
      opposite: true,
      labels: {
        formatter: (val) => formatNumberKMBT(val).toString(),
      },
    },
  };

  return (
    <Card>
      <Modal
        title={`${platform} ${username} ${title.toLocaleLowerCase()}`}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        width={850}
        footer={null}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactApexChart
            options={modalData}
            series={modalData.series}
            height={350}
            width={750}
            type="area"
          />
        </div>
      </Modal>
      <Typography.Title level={5} style={{ margin: 0 }}>
        {title}
      </Typography.Title>
      <Row
        justify="space-between"
        onClick={() => setOpenModal(true)}
        style={{ cursor: "pointer" }}
      >
        <div>
          <span style={{ fontSize: 28, fontWeight: 500, display: "block" }}>
            {data.value}
          </span>
          {negative ? (
            <ArrowDownOutlined style={{ color: "#FF354A" }} />
          ) : (
            <ArrowUpOutlined
              style={{ color: negative ? "#FF354A" : "#07B22D" }}
            />
          )}
          {data.percentage?.toFixed(2)}%
        </div>
        <ReactApexChart
          options={overallReachData}
          series={overallReachData.series}
          height={60}
          width={80}
          type="area"
        />
      </Row>
    </Card>
  );
};

export default CardGraphTrend;
