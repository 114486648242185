import { fetchCollabs } from "services/SauceService";

export async function collabsLoader(props: any) {
  const businessId = localStorage.getItem("businessId");
  if (!businessId) {
    return [];
  }

  const response = await fetchCollabs({
    businessId,
    monthly: true,
  });

  return response;
}
