import {
  Badge,
  Button,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  Popover,
  Row,
} from "antd";
import { ReactComponent as CollabsIcon } from "assets/images/collabs-icon.svg";
import { ReactComponent as HomeIcon } from "assets/images/home-icon.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as SmallLogo } from "assets/images/logo-small.svg";
import { ReactComponent as Profile } from "assets/images/profile-icon.svg";
import { ReactComponent as InfluencerIcon } from "assets/images/influencer-icon.svg";
// import { ReactComponent as MessagesIcon } from "assets/images/messages-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/images/settings-icon.svg";
import { ReactComponent as ReportIcon } from "assets/images/report-up-icon.svg";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseOutlined, DownOutlined, MenuOutlined } from "@ant-design/icons";
import { logout } from "helpers/auth-functions";
import ChangeBusinessModalControl from "components/businesses-modal/ChangeBusinessModalControl";
import { getPendingCollabsCount } from "redux-context/collabs";
import { Subscription } from "interfaces/subscription";
import { useEffect, useState } from "react";
import { InfluencerType } from "enums/InfluencerType";

const { Header, Content, Sider } = Layout;

const DefaultLayout: React.FC<any> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [selectedKey, setSelectedKey] = useState("");
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const {
    business,
    subscription,
  }: { business: any; subscription: Subscription } = useSelector(
    (state: any) => state.business
  );
  const { pendingCount } = useSelector((state: any) => state.collabs);

  const basicRemaining =
    subscription?.spotsUsageByType?.find(
      (item) => item.type === InfluencerType.basic
    )?.amountRemaining || 0;

  const premiumRemaining =
    subscription?.spotsUsageByType?.find(
      (item) => item.type === InfluencerType.premium
    )?.amountRemaining || 0;

  const dropdownItens: MenuProps["items"] = [
    {
      key: "business",
      label: <span style={{ fontWeight: 500 }}>{business?.businessName}</span>,
    },
    {
      key: "slots",
      label: (
        <>
          <div>
            <span style={{ display: "block", height: 20 }}>
              Slots available
            </span>
            <span style={{ fontWeight: 500 }}>
              {`${basicRemaining} Basic ∘ ${premiumRemaining} Premium`}
            </span>
          </div>
        </>
      ),
    },
    {
      key: "create-collab",
      label: (
        <Button type="primary" onClick={() => navigate("/collabs/new")}>
          Create collab
        </Button>
      ),
    },
    {
      key: "change-business",
      label: (
        <Button type="link" style={{ textAlign: "right" }}>
          <ChangeBusinessModalControl />
        </Button>
      ),
    },
    {
      key: "logout",
      label: (
        <Button type="link" onClick={logout} style={{ textAlign: "right" }}>
          Logout
        </Button>
      ),
    },
  ];

  const memuItems = [
    {
      key: "home",
      icon: <HomeIcon />,
      label: "Home",
      onClick: () => navigate(`/`),
    },
    {
      key: "collabs",
      icon: <CollabsIcon />,
      label: "Collabs",
      onClick: () => navigate(`/collabs`),
    },
    {
      key: "influencers",
      icon: <InfluencerIcon />,
      label: (
        <Badge
          count={pendingCount}
          offset={[25, 8]}
          color="#ffac39"
          style={{ color: "#1D2023" }}
        >
          <span style={{ color: isMobile ? "#1D2023" : "#1d2023e0" }}>
            Influencers
          </span>
        </Badge>
      ),
      onClick: () => navigate(`/influencers`),
    },
    {
      key: "report",
      icon: <ReportIcon />,
      label: "Reports",
      onClick: () => navigate(`/report?businessId=${business?._id}`),
    },
    {
      key: "settings",
      icon: <SettingsIcon />,
      label: "Settings",
      onClick: () => navigate(`/settings`),
    },
  ];

  const onClickLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    const path = pathArray.length > 1 && pathArray[1] ? pathArray[1] : "home";
    setSelectedKey(path);
  }, [location.pathname]);

  if (isMobile)
    return (
      <Layout
        style={{ minHeight: "100vh", padding: isMobile ? 0 : "40px 80px" }}
      >
        <div className={`menu content-menu ${collapsed ? "collapsed" : ""}`}>
          <div className="menu-top">
            <div className="item">
              {collapsed ? (
                <MenuOutlined onClick={() => setCollapsed(false)} />
              ) : (
                <CloseOutlined
                  className="item"
                  style={{ width: 32 }}
                  onClick={() => setCollapsed(true)}
                />
              )}
            </div>
            <div className="item center">
              <Logo style={{ width: 80 }} />
            </div>

            <div className="item last">
              <Dropdown
                menu={{ items: dropdownItens }}
                placement="bottomLeft"
                rootClassName="menu-principal"
              >
                <Profile style={{ width: 20, height: 20 }} />
              </Dropdown>
            </div>
          </div>
          <div className="vertical-content">
            <Menu
              hidden={collapsed}
              selectedKeys={[selectedKey]}
              mode="inline"
              onClick={(evt) => {
                setSelectedKey(evt.key);
                setCollapsed(true);
              }}
              onChange={(evt) => {
                console.log(evt, "Event");
                dispatch(getPendingCollabsCount());
              }}
              items={memuItems}
            />
          </div>
        </div>
        <Layout>
          <Header>
            <Menu
              mode="horizontal"
              selectedKeys={[selectedKey]}
              onClick={(evt) => setSelectedKey(evt.key)}
              onChange={(evt) => {
                console.log(evt, "Event");
                dispatch(getPendingCollabsCount());
              }}
              items={memuItems}
            />
          </Header>
          <Content style={{ margin: isMobile ? "0 16px" : "0 0 0 64px" }}>
            {children}
          </Content>
        </Layout>
      </Layout>
    );

  return (
    <Layout style={{ minHeight: "100vh", padding: isMobile ? 0 : "40px 80px" }}>
      <Sider
        collapsed={isMobile}
        style={{
          borderInlineEnd: isMobile ? "1px solid rgba(5, 5, 5, 0.06)" : "",
        }}
      >
        <div
          style={{
            height: 84,
            display: "flex",
            alignItems: "center",
            paddingLeft: isMobile ? 32 : 24,
          }}
        >
          {isMobile ? <SmallLogo /> : <Logo />}
        </div>
        <Menu
          selectedKeys={[selectedKey]}
          mode="inline"
          onClick={(evt) => setSelectedKey(evt.key)}
          onChange={(evt) => {
            console.log(evt, "Event");
            dispatch(getPendingCollabsCount());
          }}
          items={memuItems}
        />
      </Sider>
      <Layout>
        <Header>
          <Row justify="end" style={{ alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <div>
                <span style={{ display: "block", height: 20 }}>
                  Slots available
                </span>
                <span style={{ fontWeight: 500 }}>
                  {`${basicRemaining} Basic ∘ ${premiumRemaining} Premium`}
                </span>
              </div>
              <Button type="primary" onClick={() => navigate("/collabs/new")}>
                Create collab
              </Button>
              <Popover
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "right",
                    }}
                  >
                    <Button type="link" style={{ textAlign: "right" }}>
                      <ChangeBusinessModalControl />
                    </Button>
                    <Button
                      type="link"
                      onClick={onClickLogout}
                      style={{ textAlign: "right" }}
                    >
                      Logout
                    </Button>
                  </div>
                }
              >
                <span
                  style={{ display: "flex", alignItems: "center", gap: 12 }}
                >
                  <Profile /> {business?.businessName} <DownOutlined />
                </span>
              </Popover>
            </div>
          </Row>
        </Header>
        <Content style={{ margin: isMobile ? "0 16px" : "0 0 0 64px" }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
